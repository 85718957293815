import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
export default function DashboardAdmin() {
  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);
  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
  });
  return (
    <>
      <GlobalStyle />
      <Layout>
        <main>
          <h1>Admin Dashboard</h1>
        </main>
      </Layout>
    </>
  );
}
